import Cat from "./application/cat";
import Dog from "./application/dog";
import OtherPet from "./application/otherPet";
import PreviousPet from "./application/previousPet";
import Reference from "./application/reference";
import Resident from "./application/resident";
import Veterinarian from "./application/veterinarian";
import DogSimple from "./dogSimple";
import KeyValue from "./keyValue";
import UserSimple from "./userSimple";

export enum ApplicationStatuses {
  Incomplete = 1,
  Submitted,
  Reviewing,
  PendingHomeVisit,
  Accepted,
  Rejected,
  Adopted,
  Moved,
  Dogless,
  Archived,
}

export default interface Application {
  id?: number;
  applicant?: UserSimple;
  historicalApplicant?: UserSimple;
  selectedDog?: DogSimple;
  otherDogs?: DogSimple[];
  submittedDate?: Date;
  status?: KeyValue;
  adoptionReason?: string;
  adoptionTimeline?: KeyValue;
  occupation?: string;
  workSchedule?: KeyValue;
  residentsInHome?: number;
  livingSituation?: KeyValue;
  landlordAllowDogs?: boolean;
  dogBreedRestrictions?: boolean;
  dogSizeRestrictions?: boolean;
  landlordName?: string;
  landlordPhone?: string;
  residenceType?: KeyValue;
  yardFenced?: boolean;
  fenceType?: KeyValue;
  fenceHeight?: KeyValue;
  fenceSize?: KeyValue;
  exerciseLocation?: string;
  householdType?: KeyValue;
  currentPets?: boolean;
  numberOfDogs?: number;
  numberOfCats?: number;
  numberOfOtherPets?: number;
  numberOfPastPets?: number;
  givenUpPet?: boolean;
  reasonForGivingUp?: string;
  householdResidents?: Resident[];
  householdDogs?: Dog[];
  householdCats?: Cat[];
  householdOtherPets?: OtherPet[];
  householdPreviousPets?: PreviousPet[];
  familyWantsDog?: boolean;
  oppositionExplanation?: string;
  dogLocationDay?: string;
  dogLocationNight?: string;
  maxHoursAlone?: number;
  vacationHelp?: string;
  dogCarePlan?: string;
  giveUpDogReasons?: KeyValue[];
  giveUpOther?: string;
  personalReferences?: Reference[];
  petsSeeVet?: boolean;
  whyNoVet?: string;
  vetReferences?: Veterinarian[];
  acceptBarking?: boolean;
  acceptChewing?: boolean;
  acceptDigging?: boolean;
  acceptHouseTraining?: boolean;
  acceptSocial?: boolean;
  acceptSeparation?: boolean;
  acceptOther?: boolean;
  whyNoBehaviors?: string;
  otherText?: string;
  addressBehaviors?: KeyValue[];
  considerMixedBreed?: boolean;
  considerOnlyPet?: boolean;
  considerAfraid?: boolean;
  considerOnMeds?: boolean;
  considerHealthCondition?: boolean;
  considerUntrained?: boolean;
  considerSpecialNeeds?: boolean;
  genderPreference?: KeyValue;
  agePreference?: KeyValue;
  sizePreference?: KeyValue;
  halReferral?: KeyValue;
  otherInfo?: string;
}
