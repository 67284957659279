import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Autocomplete, Container, Grid, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import theme from "../../theme";
import { Inputs } from "../forms";
import { getReports } from "../../slices/report";
import * as Yup from "yup";
import {
  getMicrochipSearch,
  getRoleOptions,
  getShelterSearch,
  getSourceTypes,
  getStatusOptions,
  getSubStatus,
} from "../../slices/options";
import { getUserSearch } from "../../slices/users";
import KeyValue from "../../types/keyValue";
import CheckboxButton from "../base/CheckboxButton";
import { DateFilter } from "../../types/dateFilter";

interface Props {}

const ReportsView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { status } = useSelector((store) => store.report);
  const { subStatus } = useSelector((store) => store.options);
  const [activeReport, setActiveReport] = useState(0);
  const [dateFilter, setDateFilter] = useState(0);
  const [dogStatus, setDogStatusState] = useState<KeyValue[]>();
  const [subStatusFilters, setSubStatusFilters] = useState<string[]>();

  const setDogStatus = (value: KeyValue[]) => {
    setDogStatusState(value);
  };

  const handleSubSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      getSubStatus({
        filters: subStatusFilters,
        query: e.currentTarget.value,
      })
    );
  };

  useEffect(() => {
    let newFilters: string[] = [];

    if (!!dogStatus && !!dogStatus.length) {
      dogStatus.forEach((status: KeyValue) => {
        let newFilterName = `status:${status.id}`;
        newFilters.push(newFilterName);
      });
    }

    setSubStatusFilters(newFilters);

    dispatch(
      getSubStatus({
        filters: newFilters,
      })
    );
  }, [dispatch, dogStatus]);

  const reportOptions = [
    {
      id: 1,
      value: "Dogs",
      reportUrl: "dogs",
    },
    {
      id: 2,
      value: "Adopted Dogs",
      reportUrl: "adoptions",
      foster: true,
      intakeDate: true,
      adoptionDate: true,
    },
    {
      id: 3,
      value: "Chip Report",
      reportUrl: "microchips",
      dateRange: true,
      chipCompany: true,
    },
    {
      id: 4,
      value: "Chip Transfer",
      reportUrl: "microchip-transfer",
      dateRange: true,
      chipCompany: true,
      dogStatus: true,
    },
    {
      id: 5,
      value: "Warden Report",
      reportUrl: "warden",
      dateOption: true,
    },
    {
      id: 6,
      value: "People Report",
      reportUrl: "users",
      roles: true,
      active: true,
      reportName: "people",
    },
    {
      id: 7,
      value: "Audit Report",
      reportUrl: "audit",
      audit: true,
    },
    {
      id: 8,
      value: "Source by Dog",
      reportUrl: "dog-sources",
      intakeDate: true,
      mill: true,
    },
    {
      id: 9,
      value: "Sources Report",
      reportUrl: "sources",
    },
    {
      id: 10,
      value: "Expiring Soon Report",
      reportUrl: "expiration",
      expiration: true,
      dateRange: true,
    },
    {
      id: 11,
      value: "Peggy's Report",
      reportUrl: "foster-count",
      dateRange: true,
    },
    {
      id: 12,
      value: "Applicant Report",
      reportUrl: "applicants",
      dateRange: true,
    },
    {
      id: 13,
      value: "New Warden Report",
      reportUrl: "warden-report",
      dateRange: true,
    },
    {
      id: 14,
      value: "Spay / Neuter Report",
      reportUrl: "alteration",
    },
  ];

  const initialExpirationOptions = [
    { id: 1, value: "Rabies" },
    { id: 2, value: "Spay/Neuter" },
  ];

  const [expirationOptions, setExpirationOptions] = useState<KeyValue[]>(initialExpirationOptions);

  const initialUserStatus = [
    { id: 1, value: "Active" },
    { id: 2, value: "Inactive" },
  ];

  const [userStatus, setUserStatus] = useState<KeyValue[]>(initialUserStatus);

  const initialUserFilters = [
    { id: 1, value: "Foster Start Date" },
    { id: 2, value: "Foster End Date" },
  ];

  const [userFilter, setUserFilter] = useState<KeyValue[]>(initialUserFilters);

  const initialDateOptions = [
    { id: DateFilter.YearToDate, value: "Year to Date" },
    { id: DateFilter.MonthToDate, value: "Month to Date" },
    { id: DateFilter.LastYear, value: "Previous Year" },
    { id: DateFilter.LastMonth, value: "Previous Month" },
  ];

  const [dateOptionFilter, setDateOptionFilter] = useState<KeyValue[]>(initialDateOptions);

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().when("dateRequired", {
      is: () => reportOptions[activeReport - 1]?.dateRange,
      then: (schema) => schema.typeError("Required").required("Required"),
    }),
    endDate: Yup.date().when("dateRequired", {
      is: () => reportOptions[activeReport - 1]?.dateRange,
      then: (schema) => schema.typeError("Required").required("Required"),
    }),
    expirationOption: Yup.object().when("expirationRequired", {
      is: () => reportOptions[activeReport - 1]?.expiration,
      then: (schema) => schema.typeError("Required").required("Required"),
    }),
    microchipCompany: Yup.object().when("chipCompany", {
      is: () => reportOptions[activeReport - 1]?.chipCompany,
      then: (schema) => schema.typeError("Required").required("Required"),
    }),
  });

  const handleSubmit = (values: any) => {
    console.log(values);
    dispatch(
      getReports({
        reportUrl: reportOptions[activeReport - 1]?.reportUrl,
        startDate: reportOptions[activeReport - 1]?.dateRange
          ? values.startDate
          : reportOptions[activeReport - 1]?.roles
          ? values.userStart
          : undefined,
        endDate: reportOptions[activeReport - 1]?.dateRange
          ? values.endDate
          : reportOptions[activeReport - 1]?.roles
          ? values.userEnd
          : undefined,
        microchipCompanyId: reportOptions[activeReport - 1]?.chipCompany ? values.microchipCompany.id : undefined,
        adoptionStart: reportOptions[activeReport - 1]?.adoptionDate ? values.adoptionStart : undefined,
        adoptionEnd: reportOptions[activeReport - 1]?.adoptionDate ? values.adoptionEnd : undefined,
        intakeStart: reportOptions[activeReport - 1]?.intakeDate ? values.intakeStart : undefined,
        intakeEnd: reportOptions[activeReport - 1]?.intakeDate ? values.intakeEnd : undefined,
        foster: reportOptions[activeReport - 1]?.foster ? values.foster : undefined,
        role: reportOptions[activeReport - 1]?.roles && values.role ? values.role.id : undefined,
        active: reportOptions[activeReport - 1]?.active && values.active ? values.active.id : undefined,
        dogStatus:
          reportOptions[activeReport - 1]?.dogStatus && values.status
            ? values.status.map((m: KeyValue) => m.id)
            : undefined,
        auditStatus:
          (reportOptions[activeReport - 1]?.audit || reportOptions[activeReport - 1]?.mill) && values.auditStatus
            ? values.auditStatus.map((m: KeyValue) => m.id)
            : undefined,
        subStatus:
          (reportOptions[activeReport - 1]?.dogStatus ||
            reportOptions[activeReport - 1]?.audit ||
            reportOptions[activeReport - 1]?.mill) &&
          values.subStatus
            ? values.subStatus.map((m: KeyValue) => m.id)
            : undefined,
        source:
          reportOptions[activeReport - 1]?.audit && values.source
            ? values.source.map((m: KeyValue) => m.id)
            : undefined,
        sourceType:
          reportOptions[activeReport - 1]?.audit && values.sourceType
            ? values.sourceType.map((m: KeyValue) => m.id)
            : undefined,
        missingSource: reportOptions[activeReport - 1].audit && values.missingSource ? values.missingSource : undefined,
        rabiesExpired: reportOptions[activeReport - 1].audit && values.rabiesExpired ? values.rabiesExpired : undefined,
        missingChip: reportOptions[activeReport - 1].audit && values.missingChip ? values.missingChip : undefined,
        missingColors: reportOptions[activeReport - 1].audit && values.missingColors ? values.missingColors : undefined,
        missingMarkings:
          reportOptions[activeReport - 1].audit && values.missingMarkings ? values.missingMarkings : undefined,
        unaltered: reportOptions[activeReport - 1].audit && values.unaltered ? values.unaltered : undefined,
        reportName: reportOptions[activeReport - 1]?.reportName,
        rabiesExpiring:
          reportOptions[activeReport - 1]?.expiration && values.expirationOption?.id === 1 ? true : undefined,
        alterationExpiring:
          reportOptions[activeReport - 1]?.expiration && values.expirationOption?.id === 2 ? true : undefined,
        dateFilterId:
          reportOptions[activeReport - 1]?.dateOption && values.dateFilter ? values.dateFilter.id : undefined,
      })
    );
  };

  return (
    <Container sx={{ mb: 5 }}>
      <Grid container columns={10} sx={{ mb: 5, justifyContent: "center" }} alignItems={"center"}>
        {reportOptions.map((op) => (
          <Grid
            item
            key={op.id}
            md={2}
            xs={4}
            onClick={() => {
              setActiveReport(op.id);
            }}
            sx={{
              backgroundColor: theme.palette.background.default,
              marginX: "15px",
              marginY: activeReport === op.id ? "5px" : "15px",
              paddingY: 8,
              border:
                activeReport === op.id
                  ? `10px solid ${theme.palette.primary.main}`
                  : `2px solid ${theme.palette.primary.main}`,
              borderRadius: 5,
              cursor: "pointer",
            }}
          >
            <Typography textAlign={"center"} variant="h4">
              {op.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {activeReport > 0 && (
        <Formik
          initialValues={{
            startDate: undefined,
            endDate: reportOptions[activeReport - 1]?.expiration ? undefined : new Date(),
            microchipCompany: undefined,
            status: undefined || [],
            subStatus: undefined || [],
            adoptionStart: undefined,
            adoptionEnd: undefined,
            intakeStart: undefined,
            intakeEnd: undefined,
            foster: undefined,
            role: undefined,
            active: undefined,
            userStart: undefined,
            userEnd: undefined,
            auditStatus: undefined || [],
            source: undefined || [],
            sourceType: undefined || [],
            missingSource: true,
            rabiesExpired: true,
            missingChip: true,
            missingColors: true,
            missingMarkings: true,
            unaltered: true,
            userDate: undefined,
            expirationOption: undefined,
            dateFilter: undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate>
              <Grid container justifyContent={"center"} flexDirection={"row"}>
                {reportOptions[activeReport - 1]?.dateRange ? (
                  <>
                    <Grid item md={reportOptions[activeReport - 1]?.expiration ? 4 : 6} xs={12}>
                      <Field component={Inputs.Datepicker} name="startDate" required label="Start Date" />
                    </Grid>
                    <Grid item md={reportOptions[activeReport - 1]?.expiration ? 4 : 6} xs={12}>
                      <Field component={Inputs.Datepicker} name="endDate" required label="End Date" />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.expiration ? (
                  <>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={Inputs.Dropdown}
                        name="expirationOption"
                        required
                        label="Expiration Option"
                        options={expirationOptions}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e?.currentTarget?.value!) {
                            setExpirationOptions(
                              expirationOptions.filter((f) =>
                                f.value?.toLowerCase().includes(e.currentTarget.value.toLowerCase())
                              )
                            );
                          } else {
                            setExpirationOptions(initialExpirationOptions);
                          }
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.chipCompany ? (
                  <Grid item md={12} xs={12}>
                    <Field
                      name="microchipCompany"
                      label="Chip Company"
                      required
                      component={Inputs.OptionDropdown}
                      searchFunction={getMicrochipSearch}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.dogStatus ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="status"
                        label="Status"
                        component={Inputs.OptionDropdown}
                        searchFunction={getStatusOptions}
                        onSelected={(value: KeyValue[]) => setDogStatus(value)}
                        multiple
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="subStatus"
                        label="Sub Status"
                        component={Inputs.Dropdown}
                        onChange={handleSubSearch}
                        options={subStatus?.results! || []}
                        multiple
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.adoptionDate ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <Field component={Inputs.Datepicker} name="adoptionStart" label="Adoption Start (Optional)" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field component={Inputs.Datepicker} name="adoptionEnd" label="Adoption End (Optional)" />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.mill ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Field
                        name="auditStatus"
                        label="Status"
                        component={Inputs.OptionDropdown}
                        searchFunction={getStatusOptions}
                        onSelected={(value: KeyValue[]) => setDogStatus(value)}
                        multiple
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="subStatus"
                        label="Sub Status"
                        component={Inputs.Dropdown}
                        onChange={handleSubSearch}
                        options={subStatus?.results! || []}
                        multiple
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.intakeDate ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <Field component={Inputs.Datepicker} name="intakeStart" label="Intake Start (Optional)" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field component={Inputs.Datepicker} name="intakeEnd" label="Intake End (Optional)" />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.foster ? (
                  <Grid item xs={12}>
                    <Field
                      name="foster"
                      label="Foster (Optional)"
                      component={Inputs.OptionDropdown}
                      searchFunction={getUserSearch}
                      hideLargeResults
                      filterForm={{
                        pageNumber: 1,
                        pageSize: 10,
                        filters: ["role:3"],
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.roles ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="role"
                        label="Role"
                        component={Inputs.OptionDropdown}
                        searchFunction={getRoleOptions}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        component={Inputs.Dropdown}
                        name="active"
                        label="Status"
                        options={userStatus}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e?.currentTarget?.value!) {
                            setUserStatus(
                              userStatus.filter((f) =>
                                f.value?.toLowerCase().includes(e.currentTarget.value.toLowerCase())
                              )
                            );
                          } else {
                            setUserStatus(initialUserStatus);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="userDate"
                        label="Filter By"
                        options={userFilter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e?.currentTarget?.value!) {
                            setUserFilter(
                              userFilter.filter((f) =>
                                f.value?.toLowerCase().includes(e.currentTarget.value.toLowerCase())
                              )
                            );
                          } else {
                            setUserFilter(initialUserFilters);
                          }
                        }}
                        component={Inputs.Dropdown}
                        onSelected={(e: React.SyntheticEvent, v: any) => {
                          if (v !== null) {
                            setDateFilter(v.id);
                          } else {
                            setDateFilter(0);
                          }
                          setFieldValue("userStart", undefined);
                          setFieldValue("userEnd", undefined);
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {dateFilter !== 0 && (
                        <Field
                          component={Inputs.Datepicker}
                          name={dateFilter === 1 ? "userStart" : "userEnd"}
                          label={`Results after`}
                        />
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.audit ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="auditStatus"
                        label="Status"
                        component={Inputs.OptionDropdown}
                        searchFunction={getStatusOptions}
                        onSelected={(value: KeyValue[]) => setDogStatus(value)}
                        multiple
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="subStatus"
                        label="Sub Status"
                        component={Inputs.Dropdown}
                        onChange={handleSubSearch}
                        options={subStatus?.results! || []}
                        multiple
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="source"
                        label="Source"
                        component={Inputs.OptionDropdown}
                        searchFunction={getShelterSearch}
                        multiple
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="sourceType"
                        label="Source Type"
                        component={Inputs.OptionDropdown}
                        searchFunction={getSourceTypes}
                        multiple
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ pl: { xs: 5, md: 10 } }}>
                      <CheckboxButton
                        colorVariant="blue"
                        checked={values.missingSource}
                        onChange={() => setFieldValue("missingSource", !values.missingSource)}
                        label="Missing Source?"
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ pl: { xs: 5, md: 10 } }}>
                      <CheckboxButton
                        colorVariant="blue"
                        checked={values.rabiesExpired}
                        onChange={() => setFieldValue("rabiesExpired", !values.rabiesExpired)}
                        label="Missing/Overdue Rabies?"
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ pl: { xs: 5, md: 10 } }}>
                      <CheckboxButton
                        colorVariant="blue"
                        checked={values.missingChip}
                        onChange={() => setFieldValue("missingChip", !values.missingChip)}
                        label="Missing Chip?"
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ pl: { xs: 5, md: 10 } }}>
                      <CheckboxButton
                        colorVariant="blue"
                        checked={values.missingColors}
                        onChange={() => setFieldValue("missingColors", !values.missingColors)}
                        label="Missing Colors?"
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ pl: { xs: 5, md: 10 } }}>
                      <CheckboxButton
                        colorVariant="blue"
                        checked={values.missingMarkings}
                        onChange={() => setFieldValue("missingMarkings", !values.missingMarkings)}
                        label="Missing Markings?"
                      />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ pl: { xs: 5, md: 10 } }}>
                      <CheckboxButton
                        colorVariant="blue"
                        checked={values.unaltered}
                        onChange={() => setFieldValue("unaltered", !values.unaltered)}
                        label="Unaltered?"
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} />
                )}
                {reportOptions[activeReport - 1]?.dateOption ? (
                  <Grid item xs={12} sx={{ mb: 8 }}>
                    <Field
                      component={Inputs.Dropdown}
                      name="dateFilter"
                      label="Filter by Date"
                      options={dateOptionFilter}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e?.currentTarget?.value!) {
                          setDateOptionFilter(
                            dateOptionFilter.filter((f) =>
                              f.value?.toLowerCase().includes(e.currentTarget.value.toLowerCase())
                            )
                          );
                        } else {
                          setDateOptionFilter(initialDateOptions);
                        }
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} />
                )}
                <Grid item sx={{ mb: "22px", mt: reportOptions[activeReport - 1]?.dateRange ? 0 : "12.5px" }}>
                  <Inputs.Submit isSubmitting={status === "loading"} color="primary" text="Generate Report" />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default ReportsView;
