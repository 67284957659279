import React, { createRef, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import theme from "../../../theme";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import BannerControl from "../../base/BannerControl";
import BannerContent from "../../base/BannerContent";
import Banner from "../../../types/banner";
import { useDispatch, useSelector } from "../../../store";
import { searchEvents } from "../../../slices/events";
import { getFeaturedDog } from "../../../slices/dogs";

interface Props {
  useIsVisible: (ref: any) => boolean;
  eventRef: React.RefObject<HTMLDivElement>;
}

const staticBanners = [
  {
    title: "Our new 2025 DAILY calendar has 365 adorable dogs and makes the PERFECT holiday gift!",
    linkText: "Order Now",
    description: `Would you like to begin every day with a new furry face?  We have revamped our annual calendar into a gorgeous, high-quality piece for your desk or countertop!  Filled with 365 photos submitted from HAL adopters and dog-lovers, the bound pages can be torn off and even used as little notes.  This year's calendar is displayed on a smooth bamboo stand (be sure to save the stand so you can reuse it and just buy the calendar pages next year).  This makes a perfect gift for dog-lovers to start each day with a SMILE!  `,
    src: "https://homeatlastswag.myshopify.com/products/2025-calendar",
  },
  {
    title: "Show your support of Home at Last with our seasonal swag!",
    linkText: "HAL Store",
    description: `FREE SHIPPING on all orders!`,
    src: "https://homeatlastswag.myshopify.com/",
  },
  // {
  //   title: "Want to lend a hand?",
  //   linkText: "Help Us",
  //   description: `Fosters, volunteers, and supporters are an essential part of our rescue!`,
  //   src: "/help-us",
  // },
];

const VideoBackground: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { searchResults } = useSelector((store) => store.events);
  const { featuredDog } = useSelector((store) => store.dogs);

  const { useIsVisible, eventRef } = props;
  const [activeImage, setActiveImage] = useState(1);
  const [hover, setHover] = useState(false);
  const [banners, setBanners] = useState<Banner[]>([]);

  const bannerRef = createRef<HTMLDivElement>();

  const handleDownClick = () => {
    bannerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateBanners = useCallback(() => {
    const eventBanners: Banner[] =
      searchResults?.results?.map((e) => ({
        title: e.name,
        linkText: "Learn More",
        description: e.details,
        date: e.start,
        address: e.address,
        src: `/event/${e.id}`,
      })) || [];

    if (!!featuredDog) {
      const punctuation = /[.!?'"]/g;
      var hasPunctuation = punctuation.test(featuredDog.headline.charAt(featuredDog.headline.length - 1));

      eventBanners.push({
        title: `Meet ${featuredDog.name}, our dog of the week!`,
        linkText: `More Info`,
        description: `${featuredDog.headline}${
          hasPunctuation ? "" : "."
        } Click the button below to see more information and fill out an adoption application!`,
        src: `/dog/${featuredDog.id}`,
      });
    }

    setBanners([...eventBanners, ...staticBanners]);
  }, [searchResults, featuredDog]);

  useEffect(() => {
    updateBanners();
  }, [updateBanners, featuredDog, searchResults]);

  useEffect(() => {
    dispatch(getFeaturedDog());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchEvents({
        search: {
          pageNumber: 1,
          pageSize: 3,
          filters: ["status:2", "status:3", "featured:1"],
          orderBy: "start",
          orderDirection: "Ascending",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!hover) {
      const interval = setInterval(() => {
        setActiveImage(activeImage < banners.length ? activeImage + 1 : 1);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [activeImage, hover, banners.length]);

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          bottom: 68,
          right: 15,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 25,
          display: useIsVisible(eventRef) ? "none" : { xs: "none", lg: "flex" },
          zIndex: 100,
          cursor: "pointer",
        }}
        onClick={handleDownClick}
      >
        <KeyboardDoubleArrowDown fontSize="large" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,.5)",
        }}
      />
      <video
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        src="/static/videos/Vivi.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          color: "white",
        }}
      >
        <BannerControl activeImage={activeImage} setActiveImage={setActiveImage}>
          {banners.map((banner, index) => {
            return <BannerContent key={index} banner={banner} setHover={setHover} />;
          })}
        </BannerControl>
      </Box>
      <Box ref={bannerRef} sx={{ position: "relative", bottom: 138 }} />
    </Box>
  );
};

export default VideoBackground;
