import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import EventVM from "../../types/event";
import Moment from "react-moment";
import Banner from "../../types/banner";

interface Props {
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
  event?: EventVM;
  banner?: Banner;
}

const BannerContent: React.FC<Props> = (props) => {
  const { setHover, banner } = props;

  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginX: { md: 12, xs: 1.5 },
        flex: 1,
        height: { md: 325, xs: "100%" },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Grid container sx={{ flex: 1 }} alignItems={"center"} spacing={2}>
        <Grid item md={6} xs={12} textAlign="center">
          <Typography sx={{ fontSize: { md: "40px", xs: 30 }, fontWeight: 600 }}>{banner?.title}</Typography>
          {banner?.date && (
            <Typography>
              <Moment format="MMMM DD, yyyy">{banner.date}</Moment>
            </Typography>
          )}
          {banner?.address && (
            <>
              <Typography>{banner.address.line1}</Typography>
              <Typography>{banner.address?.line2}</Typography>
              <Typography>{`${banner.address.city}, ${banner.address.state?.value} ${banner.address.postalCode}`}</Typography>
            </>
          )}
        </Grid>
        <Grid item md={6} xs={12} textAlign="center">
          {banner?.description && (
            <Grid item sx={{ maxHeight: { md: 300, xs: 225 }, overflow: "auto" }}>
              <Typography sx={{ fontSize: { xs: 15, md: 20 } }}>{banner.description}</Typography>
            </Grid>
          )}
          {!!banner?.linkText && (
            <Grid textAlign="center" marginTop={3}>
              <Button
                sx={{
                  borderRadius: "0px",
                  border: "2px solid",
                }}
                onClick={() => window.open(banner?.src, "_blank")}
                color={"success"}
                type="button"
                variant="outlined"
                size="large"
              >
                <Typography variant={"button"}>{banner?.linkText}</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BannerContent;
