import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, DialogActions, Grid, Typography } from "@mui/material";
import StyledTable from "../base/StyledTable";
import DogSimple from "../../types/dogSimple";
import { getDogsSearch } from "../../slices/dogs";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import SearchParams from "../../types/searchParams";
import { getMyApplication, saveApplication, submitApplication } from "../../slices/applications";
import ActionButton from "../base/ActionButton";
import TableActions from "../base/TableActions";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import Dialog from "../base/Dialog";
import { ApplicationStatuses } from "../../types/application";

interface Props {
  userId: number;
}

const UserProfileTables: React.FC<Props> = (props) => {
  const { dogSearch } = useSelector((store) => store.dogs);
  const { myApplication, saveStatus } = useSelector((store) => store.applications);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeDog, setRemoveDog] = useState({ id: 0, name: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dogFilterForm, setDogFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 5,
  });

  useEffect(() => {
    dispatch(
      getDogsSearch({
        search: { ...dogFilterForm, query: "", filters: ["favorites:1"] },
        includePhotos: true,
      })
    );
  }, [dispatch, dogFilterForm]);

  useEffect(() => {
    dispatch(getMyApplication());
  }, [dispatch, props.userId]);

  const favoriteDogHeaders = ["Dog", "Dog Name", "Status"];
  const appliedDogHeaders = ["Dog", "Dog Name", "Status", "Actions"];

  let appliedDogs: DogSimple[] = [myApplication?.selectedDog!];
  appliedDogs = appliedDogs.concat(myApplication?.otherDogs!);

  const appliedDogsExist = myApplication && appliedDogs && appliedDogs.length > 0;

  const favoriteDogsExist = dogSearch?.results && dogSearch.results.length > 0;

  const dogRows =
    dogSearch?.results?.map((dog: DogSimple) => {
      return {
        dog: !!dog?.mainPhoto ? (
          <Box
            component="img"
            alt={dog.name}
            src={dog.mainPhoto}
            sx={{
              marginTop: "5px",
              borderRadius: "8px",
              width: 30,
              height: 30,
              objectFit: "cover",
            }}
          />
        ) : (
          <></>
        ),
        dogName: (
          <Box
            onClick={() => navigate(`/dog/${dog.id}`)}
            style={{ textDecoration: "underline", textDecorationColor: theme.palette.primary.main, cursor: "pointer" }}
          >
            <Typography variant="caption" color="primary" sx={{}}>
              {dog.name}
            </Typography>
          </Box>
        ),
        status: dog.status?.value,
      };
    }) || [];

  const reloadApp = () => {
    dispatch(getMyApplication());
    dispatch(handleSuccessToastState(true));
    dispatch(setSuccessMessage("Dog successfully removed from application"));
  };

  const handleRemoveDogFromApplication = (id: number) => {
    const filteredDogs = myApplication?.otherDogs?.filter((removeDog) => removeDog.id !== id);
    //if removing the selected dog
    if (myApplication?.selectedDog?.id === id) {
      //if there are other dogs on the application
      if (myApplication?.otherDogs?.length) {
        const nextSelected = myApplication?.otherDogs[0];
        const filteredWithoutNext = myApplication?.otherDogs?.filter((removeDog) => removeDog.id !== nextSelected.id);
        if (myApplication.status?.id === ApplicationStatuses.Incomplete || !myApplication.status) {
          dispatch(saveApplication({ ...myApplication, selectedDog: nextSelected, otherDogs: filteredWithoutNext }));
        } else {
          dispatch(
            submitApplication({ ...myApplication, selectedDog: nextSelected, otherDogs: filteredWithoutNext })
          ).then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
              reloadApp();
            }
          });
        }
        //if removing the selected dog and there are no other dogs on the application
      } else {
        if (myApplication.status?.id === ApplicationStatuses.Incomplete || !myApplication.status) {
          dispatch(saveApplication({ ...myApplication, selectedDog: undefined }));
        } else {
          dispatch(submitApplication({ ...myApplication, selectedDog: undefined })).then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
              reloadApp();
            }
          });
        }
      }
      //if removing one of the other dogs
    } else {
      if (myApplication?.status?.id === ApplicationStatuses.Incomplete || !myApplication?.status) {
        dispatch(saveApplication({ ...myApplication, otherDogs: filteredDogs }));
      } else {
        dispatch(submitApplication({ ...myApplication, otherDogs: filteredDogs })).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            reloadApp();
          }
        });
      }
    }
    setRemoveModal(false);
  };

  const applicationRows =
    appliedDogs?.map((dog: DogSimple) => {
      return {
        dog: !!dog?.mainPhoto ? (
          <Box
            component="img"
            alt={dog?.name}
            src={dog?.mainPhoto}
            sx={{
              marginTop: "5px",
              borderRadius: "8px",
              width: 30,
              height: 30,
              objectFit: "cover",
            }}
          />
        ) : (
          <></>
        ),
        dogName: (
          <Box
            onClick={() => navigate(`/dog/${dog?.id}`)}
            style={{ textDecoration: "underline", textDecorationColor: theme.palette.primary.main, cursor: "pointer" }}
          >
            <Typography variant="caption" color="primary" sx={{}}>
              {dog?.name}
            </Typography>
          </Box>
        ),
        status: dog?.status?.value,
        actions: (
          <TableActions
            handleRemoveDog={() => {
              setRemoveModal(true);
              setRemoveDog({ id: dog?.id, name: dog?.name });
            }}
            id={dog?.id}
            disabled={saveStatus === "loading"}
          />
        ),
      };
    }) || [];

  const changeDogPage = (pageNumber: number, pageSize: number) => {
    setDogFilterForm({ ...dogFilterForm, pageNumber: pageNumber, pageSize: pageSize });
  };

  const cancelRemove = () => {
    setRemoveModal(false);
    setRemoveDog({ id: 0, name: "" });
  };

  const favoriteTitle = (
    <Typography variant="body2" padding="1px">
      Favorite Dogs
    </Typography>
  );

  const appliedTitle = (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1} justifyContent="space-between" padding={0}>
      <Typography variant="body2">
        {myApplication?.status?.id === ApplicationStatuses.Incomplete
          ? "Application Started (Not yet submitted)"
          : "Dogs Applied For"}
      </Typography>
      <ActionButton
        text={myApplication?.status?.id === ApplicationStatuses.Incomplete ? "Edit and Submit" : "Edit Application"}
        color="warning"
        onClick={() => navigate(`/adopt/${myApplication?.selectedDog?.id}`)}
        small
        type="button"
      />
    </Box>
  );

  return (
    <Grid container spacing={1} justifyContent="center">
      {favoriteDogsExist ? (
        <Grid item xs={12} md={appliedDogsExist ? 6 : 12}>
          <StyledTable
            headers={favoriteDogHeaders}
            rows={dogRows}
            defaultPaging
            paging={true}
            page={dogFilterForm.pageNumber}
            rowsPerPage={dogFilterForm.pageSize}
            sizeVariant="small"
            title={favoriteTitle}
            totalRows={dogSearch?.totalResults}
            changePage={changeDogPage}
          />
        </Grid>
      ) : null}
      {appliedDogsExist && (myApplication.selectedDog || myApplication?.otherDogs?.length) ? (
        <Grid item xs={12} md={favoriteDogsExist ? 6 : 12}>
          <StyledTable
            headers={appliedDogHeaders}
            rows={applicationRows}
            defaultPaging
            paging={true}
            sizeVariant="small"
            title={appliedTitle}
            totalRows={appliedDogs.length}
          />
        </Grid>
      ) : myApplication?.status?.id === ApplicationStatuses.Dogless ? (
        <Grid item xs={12} md={favoriteDogsExist ? 6 : 12}>
          <Typography>
            Your application is still saved, but the dogs you applied for are no longer available. Visit our Adoptable
            Dogs page to update your application and find a new match!
          </Typography>
        </Grid>
      ) : myApplication?.status?.id === ApplicationStatuses.Incomplete ? (
        <Grid item xs={12} md={favoriteDogsExist ? 6 : 12}>
          <Typography>
            Your application is still in progress. To complete it, visit our Adoptable Dogs page and select a new dog to
            apply for. Remember to submit your application in the final step for review.
          </Typography>
        </Grid>
      ) : null}
      <Dialog
        open={removeModal}
        title={`Are you sure you would like to remove ${removeDog.name} from your application? ${
          appliedDogs.length === 1
            ? myApplication?.status?.id === ApplicationStatuses.Incomplete
              ? "Your application has not been submitted and cannot be seen by HAL volunteers. You will need to add a new dog and submit from the final application step for review."
              : "Removing all dogs will withdraw your application from consideration and make it invisible to HAL volunteers. You will need to add dogs again to re-submit your application."
            : ""
        }`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" color="secondary" onClick={cancelRemove} />
            <ActionButton
              type="button"
              text={"Remove"}
              disabled={saveStatus === "loading"}
              color="error"
              onClick={() => {
                handleRemoveDogFromApplication(removeDog.id);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default UserProfileTables;
