import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, DialogActions, Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stepper from "../base/Stepper";
import Step from "../base/Step";
import ApplicantInfoStep from "./Application/ApplicantInfoStep";
import HomeInfoStep from "./Application/HomeInfoStep";
import CareStep from "./Application/CareStep";
import ReferencesStep from "./Application/ReferencesStep";
import PreferencesStep from "./Application/PreferencesStep";
import OtherInfoStep from "./Application/OtherInfoStep";
import PetInfoStep from "./Application/PetInfoStep";
import StepperRef from "../../types/stepperRef";
import { FormikProps } from "formik";
import {
  addDogToApplication,
  clearOtherDog,
  getMyApplication,
  replaceOtherDogs,
  replaceSelectedDog,
  saveApplicantInfoStep,
  saveApplication,
  saveCareStep,
  saveHomeInfoStep,
  saveOtherStep,
  savePetInfoStep,
  savePreferencesStep,
  saveReferencesStep,
  submitApplication,
} from "../../slices/applications";
import Application from "../../types/application";
import { getDogSimpleById } from "../../slices/dogs";
import ActionButton from "../base/ActionButton";
import { updateUser } from "../../slices/users";
import { handleErrorToastState, handleSuccessToastState, setErrorMessage, setSuccessMessage } from "../../slices/toast";
import { setAuthModal, setAuthRoute } from "../../slices/auth";
import { Info } from "@mui/icons-material";
import DogSimple from "../../types/dogSimple";
import Dialog from "../base/Dialog";

interface Props {
  dogId: number;
}

const DogApplicationView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { status, myApplication, saveStatus, otherDog } = useSelector((store) => store.applications);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { user, status: userStatus } = useSelector((store) => store.users);
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState<"confirm" | "none">("none");

  const stepperRef = useRef<StepperRef>(null);
  const applicantFormRef = useRef<FormikProps<any>>(null);
  const homeFormRef = useRef<FormikProps<any>>(null);
  const petFormRef = useRef<FormikProps<any>>(null);
  const careFormRef = useRef<FormikProps<any>>(null);
  const referencesFormRef = useRef<FormikProps<any>>(null);
  const preferencesFormRef = useRef<FormikProps<any>>(null);
  const otherFormRef = useRef<FormikProps<any>>(null);
  const [currentStepName, setCurrentStepName] = useState<string>("Applicant");

  useEffect(() => {
    const load = async () => {
      if (loggedInUser) {
        await dispatch(getMyApplication());
        const dog = (await dispatch(getDogSimpleById(props.dogId))).payload;
        dispatch(addDogToApplication(dog));
      } else {
        dispatch(handleErrorToastState(true));
        dispatch(setErrorMessage("You must log in or create account to apply for a dog"));
        dispatch(setAuthRoute("login"));
        dispatch(setAuthModal(true));
      }
    };

    load();

    return () => {
      dispatch(clearOtherDog());
    };
  }, [dispatch, loggedInUser]);

  const handleNext = async (stepName: string, stepIndex: number): Promise<void> => {
    switch (stepName) {
      case "Applicant": {
        await applicantFormRef.current?.submitForm();
        if (applicantFormRef.current?.isValid) {
          stepperRef.current?.next();
          setCurrentStepName("Home");
        }
        break;
      }
      case "Home": {
        await homeFormRef.current?.submitForm();

        if (homeFormRef.current?.isValid) {
          stepperRef.current?.next();
          setCurrentStepName("Pets");
        }
        break;
      }
      case "Pets": {
        await petFormRef.current?.submitForm();

        if (petFormRef.current?.isValid) {
          stepperRef.current?.next();
          setCurrentStepName("Care");
        }
        break;
      }
      case "Care": {
        await careFormRef.current?.submitForm();
        if (careFormRef.current?.isValid) {
          stepperRef.current?.next();
          setCurrentStepName("References");
        }
        break;
      }
      case "References": {
        await referencesFormRef.current?.submitForm();

        if (referencesFormRef.current?.isValid) {
          stepperRef.current?.next();
          setCurrentStepName("Preferences");
        }
        break;
      }
      case "Preferences": {
        await preferencesFormRef.current?.submitForm();

        if (preferencesFormRef.current?.isValid) {
          stepperRef.current?.next();
          setCurrentStepName("Other");
          dispatch(clearOtherDog());
        }
        break;
      }
      case "Other": {
        await otherFormRef.current?.submitForm();

        if (otherFormRef.current?.isValid) {
          stepperRef.current?.next();
          setCurrentStepName("Other");
        }
        break;
      }
      default:
    }
  };

  const handlePrev = async (stepName: string, stepIndex: number): Promise<void> => {
    switch (stepName) {
      case "Applicant": {
        dispatch(saveApplicantInfoStep(applicantFormRef.current?.values));
        stepperRef.current?.previous();
        setCurrentStepName("Applicant");
        break;
      }
      case "Home": {
        dispatch(saveHomeInfoStep(homeFormRef.current?.values));
        stepperRef.current?.previous();
        setCurrentStepName("Applicant");
        break;
      }
      case "Pets": {
        dispatch(saveHomeInfoStep(petFormRef.current?.values));
        stepperRef.current?.previous();
        setCurrentStepName("Home");
        break;
      }
      case "Care": {
        dispatch(saveCareStep(careFormRef.current?.values));
        stepperRef.current?.previous();
        setCurrentStepName("Pets");
        break;
      }
      case "References": {
        dispatch(saveReferencesStep(referencesFormRef.current?.values));
        stepperRef.current?.previous();
        setCurrentStepName("Care");
        break;
      }
      case "Preferences": {
        await preferencesFormRef.current?.setFieldTouched("otherDogs");
        await preferencesFormRef.current?.validateField("otherDogs");
        if (preferencesFormRef.current?.errors.otherDogs) {
          return;
        } else {
          const originalSelectedDog = preferencesFormRef.current?.values.otherDogs.some(
            (dog: DogSimple) => dog.id === myApplication?.selectedDog?.id
          );

          dispatch(savePreferencesStep(preferencesFormRef.current?.values));
          stepperRef.current?.previous();
          setCurrentStepName("References");
          if (originalSelectedDog) {
            const filteredList = preferencesFormRef.current?.values.otherDogs.filter(
              (dog: DogSimple) => dog.id !== myApplication?.selectedDog?.id
            );
            dispatch(replaceOtherDogs(filteredList));
          } else {
            const newSelected = preferencesFormRef.current?.values.otherDogs[0];
            dispatch(replaceSelectedDog(newSelected));
            const filteredList = preferencesFormRef.current?.values.otherDogs.filter(
              (dog: DogSimple) => dog.id !== newSelected.id
            );
            dispatch(replaceOtherDogs(filteredList));
          }
        }
        break;
      }
      case "Other": {
        dispatch(saveOtherStep(otherFormRef.current?.values));
        stepperRef.current?.previous();
        setCurrentStepName("Preferences");
        break;
      }

      default:
    }
  };

  const handleSave = async (submit: boolean) => {
    let newApplication: Application = {};
    switch (currentStepName) {
      case "Applicant": {
        newApplication = {
          ...myApplication,
          applicant: {
            id: applicantFormRef.current?.values.id,
            firstName: applicantFormRef.current?.values.firstName,
            lastName: applicantFormRef.current?.values.lastName,
            age: applicantFormRef.current?.values.age,
            email: applicantFormRef.current?.values.email,
            phone: applicantFormRef.current?.values.phone,
            secondaryPhone: applicantFormRef.current?.values.phone2,
            address: {
              line1: applicantFormRef.current?.values.line1,
              line2: applicantFormRef.current?.values.line2,
              city: applicantFormRef.current?.values.city,
              state: applicantFormRef.current?.values.state,
              postalCode: applicantFormRef.current?.values.postalCode,
            },
          },
          occupation: applicantFormRef.current?.values.occupation,
          workSchedule: applicantFormRef.current?.values.workSchedule,
          adoptionReason: applicantFormRef.current?.values.adoptionReason,
          adoptionTimeline: applicantFormRef.current?.values.adoptionTimeline,
        };
        dispatch(saveApplicantInfoStep(applicantFormRef.current?.values));
        break;
      }
      case "Home": {
        newApplication = {
          ...myApplication,
          ...homeFormRef.current?.values,
        };
        dispatch(saveHomeInfoStep(homeFormRef.current?.values));
        break;
      }
      case "Pets": {
        newApplication = {
          ...myApplication,
          ...petFormRef.current?.values,
        };
        dispatch(savePetInfoStep(petFormRef.current?.values));
        break;
      }
      case "Care": {
        newApplication = {
          ...myApplication,
          ...careFormRef.current?.values,
        };
        dispatch(saveCareStep(careFormRef.current?.values));
        break;
      }
      case "References": {
        newApplication = {
          ...myApplication,
          petsSeeVet: referencesFormRef.current?.values.petsSeeVet,
          whyNoVet: referencesFormRef.current?.values.whyNoVet,
          personalReferences: [
            referencesFormRef.current?.values.personalReference1,
            referencesFormRef.current?.values.personalReference2,
          ],
        };

        newApplication.vetReferences = [];

        if (
          !!referencesFormRef.current?.values?.vetReference1?.name ||
          !!referencesFormRef.current?.values?.vetReference1?.phone ||
          !!referencesFormRef.current?.values?.vetReference1?.city ||
          !!referencesFormRef.current?.values?.vetReference1?.state ||
          !!referencesFormRef.current?.values?.vetReference1?.petsOnFile
        ) {
          newApplication.vetReferences = [referencesFormRef.current?.values.vetReference1];
        }

        if (
          !!referencesFormRef.current?.values?.vetReference2?.name ||
          !!referencesFormRef.current?.values?.vetReference2?.phone ||
          !!referencesFormRef.current?.values?.vetReference2?.city ||
          !!referencesFormRef.current?.values?.vetReference2?.state ||
          !!referencesFormRef.current?.values?.vetReference2?.petsOnFile
        ) {
          newApplication.vetReferences = [
            ...newApplication.vetReferences,
            referencesFormRef.current?.values.vetReference2,
          ];
        }

        dispatch(saveReferencesStep(referencesFormRef.current?.values));
        break;
      }
      case "Preferences": {
        const originalSelectedDog = preferencesFormRef.current?.values.otherDogs.some(
          (dog: DogSimple) => dog?.id === myApplication?.selectedDog?.id
        );

        newApplication = {
          ...myApplication,
          ...preferencesFormRef.current?.values,
          selectedDog: originalSelectedDog
            ? myApplication?.selectedDog
            : preferencesFormRef.current?.values.otherDogs.length && !originalSelectedDog
            ? preferencesFormRef.current?.values.otherDogs[0]
            : undefined,
        };
        dispatch(savePreferencesStep(preferencesFormRef.current?.values));
        break;
      }
      case "Other": {
        newApplication = {
          ...myApplication,
          ...otherFormRef.current?.values,
        };
        dispatch(saveOtherStep(otherFormRef.current?.values));
        break;
      }
      default:
    }

    let dogList: DogSimple[] = [];
    let dogAvailable = true;

    myApplication?.otherDogs?.forEach(async (dog) => {
      let dbDog = (await dispatch(getDogSimpleById(dog?.id!))).payload as DogSimple;
      if (dbDog.status?.id! === 2 || dbDog.status?.id === 4 || dbDog.status?.id === 10) {
        if (!!dbDog) {
          dogList.push(dbDog);
        }
      }
    });

    let selectedDog = (await dispatch(getDogSimpleById(myApplication?.selectedDog?.id!))).payload as DogSimple;
    if (selectedDog.status?.id! !== 2 && selectedDog.status?.id !== 4 && selectedDog.status?.id !== 10) {
      selectedDog = dogList[0];
      dogAvailable = false;
    }

    newApplication = {
      ...newApplication,
      selectedDog: selectedDog,
      otherDogs: dogList,
    };

    await dispatch(updateUser({ ...user, ...newApplication?.applicant }));

    if (submit && !!dogAvailable) {
      const result = await dispatch(submitApplication(newApplication!));
      if (result && result.payload && result.meta.requestStatus === "fulfilled") {
        dispatch(handleSuccessToastState(true));
        dispatch(
          setSuccessMessage(
            !myApplication?.status?.id || myApplication?.status?.id === 1
              ? "Application Submitted"
              : "Application Updated"
          )
        );
        navigate("/user-profile");
      }
    } else {
      const result = await dispatch(saveApplication(newApplication!));
      if (result && result.payload && result.meta.requestStatus === "fulfilled") {
        if (!dogAvailable) {
          dispatch(handleErrorToastState(true));
          dispatch(
            setErrorMessage(
              "The dog you are trying to apply for is no longer available! Your answers have been saved along with any other dogs you have selected. Please review before submitting again."
            )
          );
        } else {
          dispatch(handleSuccessToastState(true));
          dispatch(setSuccessMessage("Application Saved"));
        }
        navigate("/user-profile");
      }
    }
  };

  const handleSubmit = async () => {
    await otherFormRef.current?.submitForm();

    if (otherFormRef.current?.isValid) {
      await handleSave(true);
    }
  };

  const handleUnfinishedSave = () => {
    setDialogOpen("confirm");
  };

  return !!loggedInUser ? (
    <Box sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography textAlign={"center"} variant="h1">
          Dog Adoption Application
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography textAlign={"center"} variant="h1" sx={{ paddingRight: "20px" }}>
          ({otherDog ? otherDog.name : myApplication?.selectedDog?.name})
        </Typography>
        <ActionButton
          type="button"
          onClick={() => {
            if (myApplication?.status?.id === 1 || !myApplication?.status) {
              handleUnfinishedSave();
            } else {
              handleSave(true);
            }
          }}
          text="Save"
          disabled={saveStatus === "loading" || userStatus === "loading"}
        />
        <Grid sx={{ display: { xs: "none", md: "inline" } }}>
          <Tooltip title="Busy? Save your application and come finish it later!">
            <Info fontSize="small" sx={{ marginLeft: "5px", marginTop: "-15px" }} />
          </Tooltip>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", display: { xs: "inline", md: "none" } }}>
        <Typography variant="caption">Busy? Save your application and come finish it later!</Typography>
      </Box>
      {status === "idle" ? (
        <Box sx={{ flex: "1 1 auto;", paddingTop: "15px" }}>
          <Stepper
            disableStepClicks
            onNext={handleNext}
            onPrev={handlePrev}
            onSubmit={handleSubmit}
            status={saveStatus}
            secondStatus={userStatus}
            ref={stepperRef}
          >
            <Step label="Applicant">
              <Box>
                <ApplicantInfoStep formikRef={applicantFormRef} />
              </Box>
            </Step>
            <Step label="Home">
              <Box sx={{ width: "100%" }}>
                <HomeInfoStep formikRef={homeFormRef} />
              </Box>
            </Step>
            <Step label="Pets">
              <Box sx={{ width: "100%" }}>
                <PetInfoStep formikRef={petFormRef} />
              </Box>
            </Step>
            <Step label="Care">
              <Box sx={{ width: "100%" }}>
                <CareStep formikRef={careFormRef} />
              </Box>
            </Step>
            <Step label="References">
              <Box sx={{ width: "100%" }}>
                <ReferencesStep formikRef={referencesFormRef} />
              </Box>
            </Step>
            <Step label="Preferences">
              <Box sx={{ width: "100%" }}>
                <PreferencesStep formikRef={preferencesFormRef} />
              </Box>
            </Step>
            <Step label="Other">
              <Box sx={{ width: "100%" }}>
                <OtherInfoStep formikRef={otherFormRef} />
              </Box>
            </Step>
          </Stepper>
        </Box>
      ) : (
        <Box textAlign={"center"}>
          <Typography variant="h1">Loading application, please wait...</Typography>
        </Box>
      )}
      <Dialog
        open={dialogOpen === "confirm"}
        title={`Your application is incomplete. To proceed with application review, you must submit it in the final step.`}
      >
        <Box>
          <Typography variant="body1" align="center">
            Are you sure you want to save without submitting? You can return anytime to finish.
          </Typography>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Back" color="secondary" onClick={() => setDialogOpen("none")} />
            <ActionButton
              type="button"
              text={"Save"}
              disabled={status === "loading" ? true : false}
              onClick={async () => {
                handleSave(false);
                setDialogOpen("none");
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  ) : (
    <></>
  );
};

export default DogApplicationView;
