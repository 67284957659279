import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../../theme";
import { Field, Form, Formik } from "formik";
import ActionButton from "../base/ActionButton";
import { Inputs } from "../forms";
import { getUserSearch } from "../../slices/users";
import * as Yup from "yup";
import { useDispatch } from "../../store";
import { emailFile, emailMergedFile } from "../../slices/file";
import getEmailRegex from "../../helpers/emailRegex";
import { searchVet } from "../../slices/vet";

interface Props {
  onSuccessClose: () => void;
  onCancel: () => void;
  submittingStatus?: boolean;
  fileIds: number[];
}

const EmailDocumentView: React.FC<Props> = (props) => {
  const { onSuccessClose, onCancel, submittingStatus, fileIds } = props;
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState<"vet" | "user">("user");

  const validationSchema = Yup.object().shape({
    existingEmail: Yup.boolean(),
    user: Yup.object().when(["existingEmail", "vetEmail"], {
      is: (existingEmail: boolean, vetEmail: boolean) => !!existingEmail && !vetEmail,
      then: (schema) => schema.required("Required").typeError("Required").nullable(),
    }),
    vet: Yup.object().when(["existingEmail", "vetEmail"], {
      is: (existingEmail: boolean, vetEmail: boolean) => !!existingEmail && !!vetEmail,
      then: (schema) => schema.required("Required").typeError("Required").nullable(),
    }),
    email: Yup.string(),
    typedEmail: Yup.string().when("existingEmail", {
      is: (existingEmail: boolean) => !existingEmail,
      then: (schema) =>
        schema
          .email("Must be a valid email")
          .matches(getEmailRegex(), "Must be a valid email")
          .required("Required")
          .typeError("Required"),
    }),
  });
  const handleSubmit = (values: any) => {
    if (fileIds.length === 1) {
      dispatch(emailFile({ fileId: fileIds[0], email: values.existingEmail ? values.email : values.typedEmail })).then(
        (result) => {
          if (result.meta.requestStatus === "fulfilled") {
            onSuccessClose();
          }
        }
      );
    } else {
      let fileList: string[] = [];
      fileIds.forEach((f) => {
        fileList.push(`id:${f}`);
      });

      dispatch(
        emailMergedFile({ fileIds: fileList, email: values.existingEmail ? values.email : values.typedEmail })
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          onSuccessClose();
        }
      });
    }
  };

  const handleRerender = () => {
    if (dropdown === "user") {
      setDropdown("vet");
    } else {
      setDropdown("user");
    }
  };

  return (
    <Box
      sx={{ backgroundColor: theme.palette.background.paper, borderRadius: "15px", padding: "15px", width: "400px" }}
    >
      <Typography textAlign={"center"} variant="h4">
        Email File
      </Typography>
      <Container sx={{ marginTop: "15px" }}>
        <Formik
          initialValues={{
            existingEmail: true,
            user: undefined,
            email: "",
            vet: undefined,
            vetEmail: false,
            typedEmail: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate>
              <Grid container justifyContent={"space-around"}>
                <Grid item xs={12}>
                  <Field component={Inputs.Switch} name="existingEmail" label="Send to someone with an account?" />
                </Grid>

                {values.existingEmail ? (
                  <>
                    <Grid item xs={12}>
                      <Field
                        component={Inputs.Switch}
                        name="vetEmail"
                        label="Send to vet?"
                        onChange={() => handleRerender()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {dropdown === "user" && (
                        <Field
                          name="user"
                          label="Search emails"
                          component={Inputs.OptionDropdown}
                          searchFunction={getUserSearch}
                          showId
                          hideLargeResults
                          required
                          filterForm={{ filters: ["active:1"] }}
                          onSelected={(value: any) => {
                            if (value && value.email) {
                              setFieldValue("email", value.email);
                            } else {
                              setFieldValue("email", "");
                            }
                          }}
                        />
                      )}
                      {dropdown === "vet" && (
                        <Field
                          name="vet"
                          label="Search vet emails"
                          component={Inputs.OptionDropdown}
                          searchFunction={searchVet}
                          showId
                          hideLargeResults
                          required
                          filterForm={{ filters: ["email:1"] }}
                          onSelected={(value: any) => {
                            if (value && value.email) {
                              setFieldValue("email", value.email);
                            } else {
                              setFieldValue("email", "");
                            }
                          }}
                        />
                      )}
                    </Grid>
                    {(!!values.user || (values.vetEmail && !!values.vet)) && (
                      <Typography textAlign={"center"} variant="body2">
                        {values.email || ""}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Field name="typedEmail" label="Enter email" component={Inputs.Text} required />
                  </Grid>
                )}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                ></Box>
                <Grid container justifyContent={"space-around"} sx={{ my: 2 }}>
                  <Grid item>
                    <ActionButton type="button" text="Cancel" color="success" onClick={onCancel} />
                  </Grid>
                  <Grid item>
                    <Inputs.Submit
                      text="Send"
                      isSubmitting={submittingStatus ? submittingStatus : false}
                      disabled={!values.email}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default EmailDocumentView;
