import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import DogSimple from "../../types/dogSimple";
import TableActions from "../base/TableActions";
import ActionButton from "../base/ActionButton";
import StyledTable from "../base/StyledTable";
import { getApplicationsSearch } from "../../slices/applications";
import { getDogSimpleById, resetSimpleDog } from "../../slices/dogs";
import Moment from "react-moment";
import { ApplicationStatuses } from "../../types/application";
import ApplicationSimple from "../../types/applicationSimple";
import SearchParams from "../../types/searchParams";

interface Props {
  userId: number;
}

const UserApplicationTable: React.FC<Props> = (props) => {
  const { applicationsSearchResults, status } = useSelector((store) => store.applications);
  const dispatch = useDispatch();

  const [activeApp, setActiveApp] = useState<{
    appliedDogs: DogSimple[];
    userApplication: ApplicationSimple;
    appliedDogsExist: boolean;
  }>();

  const [filterForm, setFilterForm] = useState<SearchParams>({
    query: "",
    filters: [`applicant:${props.userId}`],
    pageNumber: 1,
    pageSize: 5,
    orderBy: "id",
    orderDirection: "Descending",
  });

  useEffect(() => {
    dispatch(
      getApplicationsSearch({
        search: { ...filterForm },
      })
    );
  }, [dispatch, filterForm]);

  useEffect(() => {
    if (!!applicationsSearchResults && applicationsSearchResults.page === 1) {
      const userApplication = applicationsSearchResults?.results[0];
      let dogList: DogSimple[] = [userApplication?.selectedDog!];
      dogList = dogList.concat(userApplication?.otherDogs!).filter((x) => !!x);
      const appliedDogsExist = userApplication && dogList && dogList.length > 0;

      setActiveApp({
        appliedDogs: dogList,
        userApplication: userApplication,
        appliedDogsExist: appliedDogsExist,
      });
    }
  }, [applicationsSearchResults]);

  const changePage = (pageNumber: number, pageSize: number) => {
    setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
  };

  const appliedDogHeaders = ["Dog Name", "Interest", "Status", "Foster", "Actions"];

  const dogsAppliedRows = activeApp?.appliedDogs?.length
    ? activeApp?.appliedDogs?.map((dog: DogSimple) => {
        return {
          dogName: (
            <Box>
              <Typography variant="caption" color="primary">
                {dog?.name}
              </Typography>
            </Box>
          ),
          interest: activeApp?.userApplication?.selectedDog?.id === dog?.id ? "Primary" : "Secondary",
          status: !!dog.subStatus ? `${dog?.status?.value} - ${dog?.subStatus?.value}` : dog?.status?.value,
          foster: !!dog.foster ? `${dog.foster.value}` : "",
          actions: <TableActions id={dog?.id} handleView={() => window.open(`/admin/dog/${dog?.id}`, "_blank")} />,
        };
      })
    : [];

  const applicationHeaders = ["Status", "Saved", "Submitted", "Selected Dogs", "Actions"];
  const userApplications: ApplicationSimple[] = applicationsSearchResults?.results as ApplicationSimple[];

  const applicationRows = userApplications?.map((app: ApplicationSimple) => {
    let dogList: DogSimple[] = [app?.selectedDog!];
    dogList = dogList.concat(app?.otherDogs?.filter((x) => !!x)!);

    return {
      status:
        !!app.originalStatus && app.status?.id === ApplicationStatuses.Archived
          ? `${app.status?.value} - ${app.originalStatus?.value}`
          : app.status?.value,
      saved: !!app.startedDate ? `${new Date(app.startedDate).toLocaleDateString()}` : "",
      submitted: !!app.termsAcceptedDate ? `${new Date(app.termsAcceptedDate).toLocaleDateString()}` : "Not Submitted",
      selectedDogs: dogList?.length
        ? dogList
            ?.filter((x) => !!x)
            .map((x) => x.name!)
            ?.join(", ")
        : `All dogs removed ${
            app.status?.id === ApplicationStatuses.Incomplete ||
            app.originalStatus?.id === ApplicationStatuses.Incomplete
              ? "by user"
              : app.status?.id === ApplicationStatuses.Dogless || app.originalStatus?.id === ApplicationStatuses.Dogless
              ? "automatically"
              : ""
          }`,
      actions: (
        <TableActions
          disableView={
            app.status?.id === ApplicationStatuses.Incomplete || app.status?.id === ApplicationStatuses.Archived
          }
          id={app?.id}
          handleView={() => window.open(`/admin/applications/${app?.id}`, "_blank")}
        />
      ),
    };
  });

  const historyTitle = (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1} justifyContent="space-between" padding={0}>
      <Typography variant="body2">Application History</Typography>
      {activeApp?.userApplication?.status?.id !== ApplicationStatuses.Incomplete && (
        <ActionButton
          text={"Current Application"}
          color="warning"
          onClick={() => {
            if (
              activeApp?.userApplication.status?.id !== 9 &&
              activeApp?.userApplication.status?.id !== 10 &&
              activeApp?.userApplication.selectedDog
            ) {
              dispatch(getDogSimpleById(activeApp?.userApplication?.selectedDog?.id!));
            } else {
              dispatch(resetSimpleDog());
            }
            window.open(`/admin/applications/${activeApp?.userApplication.id}`, "_blank");
          }}
          small
          type="button"
        />
      )}
    </Box>
  );

  const appliedTitle = (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1} justifyContent="space-between" padding={0}>
      <Typography variant="body2">
        {activeApp?.userApplication?.status?.id === ApplicationStatuses.Incomplete
          ? "Dogs on Current Application"
          : "Dogs Applied For"}
      </Typography>
    </Box>
  );

  return (
    <Grid container spacing={1} justifyContent="center">
      {status === "loading" ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : applicationRows?.length ? (
        <Grid marginTop={3} item xs={12}>
          <StyledTable
            headers={applicationHeaders}
            rows={applicationRows}
            defaultPaging
            paging={true}
            page={applicationsSearchResults?.page}
            sizeVariant="small"
            title={historyTitle}
            totalRows={applicationsSearchResults?.totalResults}
            changePage={changePage}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography>No current applications in progress.</Typography>
        </Grid>
      )}
      {status === "loading" && !activeApp?.appliedDogsExist ? (
        <></>
      ) : activeApp?.appliedDogsExist &&
        (activeApp?.userApplication.selectedDog || activeApp?.userApplication?.otherDogs?.length) ? (
        <Grid item xs={12} marginTop={3}>
          <StyledTable
            headers={appliedDogHeaders}
            rows={dogsAppliedRows}
            defaultPaging
            paging={true}
            sizeVariant="small"
            title={appliedTitle}
            totalRows={activeApp?.appliedDogs?.length}
          />
        </Grid>
      ) : activeApp?.userApplication?.status?.id === 9 ? (
        <Grid item xs={12}>
          <Typography>
            Application Status: Submitted (No Dog) - This applicant previously applied for a dog that is no longer
            available. They have been notified that they need to update their application with new dogs of interest.
          </Typography>
        </Grid>
      ) : activeApp?.userApplication?.status?.id === 1 ? (
        <Grid item xs={12}>
          <Typography>
            Application Status: Incomplete{" "}
            {!!activeApp?.userApplication?.submittedDate ? (
              <Moment format="MM/DD/YYYY">{activeApp?.userApplication?.submittedDate!}</Moment>
            ) : (
              ""
            )}{" "}
            - This applicant has either withdrawn their application or has started an application but has not finished
            it yet. They have been notified that they need to update their application with new dogs of interest if they
            wish to be considered for adoption.
          </Typography>
        </Grid>
      ) : activeApp?.userApplication?.status?.id === 7 ? (
        <Grid item xs={12}>
          <Typography>
            Application Status: Adopted - This applicant has adopted a dog from HAL! Their application has been reset so
            they can apply for more dogs in the future.
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography>No current dogs.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default UserApplicationTable;
